import {Box, Heading, HeadingProps, useColorModeValue} from "@chakra-ui/react"

interface HeadingBoxProps extends HeadingProps {
  children?: React.ReactNode
}

const HeadingBox = ({children, ...props}: HeadingBoxProps) => {
  const backgroundColor = useColorModeValue("gray.200", "gray.700")

  return (
    <Box p="2" bg={backgroundColor} borderRadius="md">
      <Heading {...props}>{children}</Heading>
    </Box>
  )
}

export type {HeadingBoxProps}
export {HeadingBox}
