import {Link, IconButton, IconButtonProps} from "@chakra-ui/react"
import {FaGitlab} from "react-icons/fa"

interface GitlabButtonProps extends Omit<IconButtonProps, "aria-label"> {}

const GitlabButton = (props: GitlabButtonProps) => {
  return (
    <Link target="_blank" href="https://gitlab.com/Jpac14/rima">
      <IconButton icon={<FaGitlab />} aria-label="Gitlab" {...props} />
    </Link>
  )
}

export type {GitlabButtonProps}
export {GitlabButton}
