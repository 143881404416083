import {useColorMode, useColorModeValue, IconButton, IconButtonProps} from "@chakra-ui/react"
import {FaMoon, FaSun} from "react-icons/fa"

interface ColorModeSwitcherProps extends Omit<IconButtonProps, "aria-label"> {}

const ColorModeSwitcher = (props: ColorModeSwitcherProps) => {
  const {toggleColorMode} = useColorMode()
  const text = useColorModeValue("dark", "light")
  const SwitchIcon = useColorModeValue(FaMoon, FaSun)

  return (
    <IconButton
      size="md"
      fontSize="lg"
      aria-label={`Switch to ${text} mode`}
      color="current"
      onClick={toggleColorMode}
      icon={<SwitchIcon />}
      {...props}
    />
  )
}

export type {ColorModeSwitcherProps}
export {ColorModeSwitcher}
