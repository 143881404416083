import {LetterBox, LetterBoxProps} from "@/components"
import {Center, HStack} from "@chakra-ui/react"

import {WordInput} from "@/util"

interface WordRowProps extends Omit<LetterBoxProps, "letter" | "valid"> {
  word: WordInput
}

const WordRow = ({word, ...props}: WordRowProps) => {
  return (
    <Center p={1}>
      <HStack>
        {Array.from(word.text.toLowerCase()).map((letter, letterIndex) => (
          <LetterBox letter={letter} valid={word.valid} key={letterIndex} {...props} />
        ))}
      </HStack>
    </Center>
  )
}

export type {WordRowProps}
export {WordRow}
