import {Center, VStack, StackProps, useColorModeValue} from "@chakra-ui/react"

interface GameContainerProps extends StackProps {
  children?: React.ReactNode
}

const GameContainer = ({children, ...props}: GameContainerProps) => {
  const borderColor = useColorModeValue("gray.300", "gray.400")

  return (
    <Center h="100vh">
      <VStack border="solid" borderColor={borderColor} p="8" borderRadius="md" h="md" {...props}>
        {children}
      </VStack>
    </Center>
  )
}

export type {GameContainerProps}
export {GameContainer}
