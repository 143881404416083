import React from "react"
import ReactDOM from "react-dom"

import * as serviceWorkerRegistration from "./serviceWorkerRegistration"

import App from "./App"

import {ChakraProvider, extendTheme} from "@chakra-ui/react"

import "@fontsource/inter"
import "@egjs/react-flicking/dist/flicking.css"

const theme = extendTheme({
  fonts: {
    heading: "Inter",
    body: "Inter",
  },
})

ReactDOM.render(
  <React.StrictMode>
    <ChakraProvider theme={theme}>
      <App />
    </ChakraProvider>
  </React.StrictMode>,
  document.getElementById("root")
)

serviceWorkerRegistration.register()
