import {
  GitlabButton,
  HelpButton,
  ColorModeSwitcher,
  RestartButton,
  SettingsButton,
} from "@/components"
import {Box, HStack, BoxProps} from "@chakra-ui/react"

interface FooterProps extends BoxProps {}

const Footer = (props: FooterProps) => {
  return (
    <Box position="absolute" right="0" bottom="0" p="2" {...props}>
      <HStack>
        <GitlabButton />
        <ColorModeSwitcher />
        <SettingsButton />
        <RestartButton />
        <HelpButton />
      </HStack>
    </Box>
  )
}

export type {FooterProps}
export {Footer}
