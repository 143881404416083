import useStore from "@/store"

import {Input, useColorModeValue} from "@chakra-ui/react"

interface InputFormProps {
  inputChange: () => void
  submit: () => void
}

const InputForm = ({inputChange, submit}: InputFormProps) => {
  const [input, setInput] = useStore((state) => [state.input, state.setInput])

  const inputBackgroundColor = useColorModeValue("gray.100", "gray.600")

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault()
        submit()
      }}
    >
      <Input
        value={input}
        onChange={(e) => {
          setInput(e.target.value)
          inputChange()
        }}
        bg={inputBackgroundColor}
      />
    </form>
  )
}

export type {InputFormProps}
export {InputForm}
