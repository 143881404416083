import create from "zustand"

import randomWords from "random-words"

import {RhymeWord, WordInput, getRhymingWords} from "@/util"

interface Store {
  startWord: string
  setStartWord: (word: string) => void
  generateStartWord: () => void

  rhymingWords: RhymeWord[]
  fetchRhymingWords: () => void

  roundStarted: boolean
  startRound: () => void
  stopRound: () => void

  input: string
  setInput: (input: string) => void

  words: WordInput[]
  addWord: (word: WordInput) => void
  resetWords: () => void

  initialize: (generate: boolean) => void
}

const useStore = create<Store>((set, get) => ({
  startWord: "",
  setStartWord: (word: string) => set(() => ({startWord: word.toLowerCase()})),
  generateStartWord: () => get().setStartWord(randomWords({exactly: 1})[0].toLowerCase()),

  rhymingWords: [],
  fetchRhymingWords: async () => {
    const words = await getRhymingWords(get().startWord)
    set((state) => ({...state, rhymingWords: words}))
  },

  roundStarted: false,
  startRound: () => set((state) => ({...state, roundStarted: true})),
  stopRound: () => set((state) => ({...state, roundStarted: false})),

  input: "",
  setInput: (input: string) => set((state) => ({...state, input})),

  words: [],
  addWord: (word: WordInput) => set((state) => ({...state, words: [...state.words, word]})),
  resetWords: () => set((state) => ({...state, words: []})),

  initialize: (generate: boolean) => {
    if (generate) get().generateStartWord()
    get().fetchRhymingWords()
    get().stopRound()
    get().setInput("")
    get().resetWords()
  },
}))

export type {Store}
export default useStore
