import axios from "axios"

interface WordInput {
  text: string
  valid: boolean
}
interface RhymeWord {
  word: string
  score: number
  numSyllables: number
}

async function getRhymingWords(startWord: string): Promise<RhymeWord[]> {
  const res = await axios.get(`https://api.datamuse.com/words?rel_rhy=${startWord}&max=10000`)
  const data = (await res.data) as RhymeWord[]
  return data
}

export type {RhymeWord, WordInput}
export {getRhymingWords}
