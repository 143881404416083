import {useRef, useEffect} from "react"
import useStore from "@/store"

import {WordList, HeadingBox, Footer, InputForm, Banner, GameContainer} from "@/components"

import Flicking from "@egjs/react-flicking"

function App() {
  const store = useStore()
  const {
    initialize,
    startWord,
    words,
    addWord,
    rhymingWords,
    roundStarted,
    startRound,
    input,
    setInput,
  } = store

  useEffect(() => {
    initialize(true)
  }, [initialize])

  const flickingRef = useRef<Flicking>(null)

  const validateWord = (word: string): boolean => {
    return !(
      word === startWord ||
      words.some((previousWord) => previousWord.text === word) ||
      !rhymingWords.some((rhyme) => rhyme.word === word)
    )
  }

  const inputChange = () => {
    if (roundStarted) return
    startRound()
  }

  const submit = () => {
    setInput("")
    if (input === "") return

    const valid = validateWord(input)
    addWord({text: input, valid})

    if (words.length !== 0) flickingRef.current?.moveTo(words.length - 1)
  }

  return (
    <>
      <GameContainer>
        <HeadingBox>{startWord}</HeadingBox>
        <WordList words={words} ref={flickingRef} />
        <InputForm inputChange={inputChange} submit={submit} />
      </GameContainer>

      <Banner />
      <Footer />
    </>
  )
}

export default App
