import {FormControl, FormLabel, FormHelperText, Input, InputProps} from "@chakra-ui/react"

interface StartWordInputProps extends Omit<InputProps, "value" | "onChange"> {
  value: string
  onChange: (value: string) => void
}

const StartWordInput = ({value, onChange, ...props}: StartWordInputProps) => {
  return (
    <FormControl>
      <FormLabel>Start Word</FormLabel>
      <Input value={value} onChange={(e) => onChange(e.target.value)} {...props} />
      <FormHelperText>Enter the word you want to start with?</FormHelperText>
    </FormControl>
  )
}

export type {StartWordInputProps}
export {StartWordInput}
