import {CenterProps, Center, Text} from "@chakra-ui/react"

interface LetterBoxProps extends CenterProps {
  letter: string
  valid: boolean
}

const LetterBox = ({letter, valid, ...props}: LetterBoxProps) => {
  return (
    <Center w="10" h="10" borderRadius="md" bg={valid ? "green.300" : "red.300"} {...props}>
      <Text>{letter}</Text>
    </Center>
  )
}

export type {LetterBoxProps}
export {LetterBox}
