import {forwardRef, Ref} from "react"
import useStore from "@/store"

import {WordRow} from "@/components"
import {chakra} from "@chakra-ui/react"
import Flicking, {FlickingProps} from "@egjs/react-flicking"

const ChakraFlicking = chakra(Flicking)

interface WordListProps extends FlickingProps {}

const WordList = forwardRef((props: WordListProps, ref: Ref<Flicking>) => {
  const words = useStore((state) => state.words)

  return (
    <ChakraFlicking h="2xs" w="xs" horizontal={false} ref={ref} borderRadius="md" {...props}>
      {words.map((word, index) => (
        <div key={index}>
          <WordRow word={word} />
        </div>
      ))}
    </ChakraFlicking>
  )
})

export type {WordListProps}
export {WordList}
