import useStore from "@/store"
import {useState} from "react"

import {StartWordInput} from "@/components"

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalFooter,
  ModalBody,
  Button,
  ModalProps,
} from "@chakra-ui/react"

interface SettingsModalProps extends Omit<ModalProps, "children"> {
  isOpen: boolean
  onClose: () => void
}

const SettingsModal = ({isOpen, onClose, ...props}: SettingsModalProps) => {
  const [startWord, setStartWord, initialize] = useStore((state) => [
    state.startWord,
    state.setStartWord,
    state.initialize,
  ])
  const [startWordInput, setStartWordInput] = useState(startWord)

  const onSave = () => {
    setStartWord(startWordInput)
    initialize(false)
    onClose()
  }

  const onCancel = () => {
    setStartWordInput(startWord)
    onClose()
  }

  return (
    <>
      <Modal isOpen={isOpen} onClose={onCancel} isCentered {...props}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Settings</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <StartWordInput value={startWordInput} onChange={setStartWordInput} />
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={onSave}>
              Save
            </Button>
            <Button colorScheme="red" onClick={onCancel}>
              Cancel
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}

export type {SettingsModalProps}
export {SettingsModal}
