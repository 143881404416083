import useStore from "@/store"

import {
  Popover,
  PopoverTrigger,
  IconButton,
  Portal,
  PopoverContent,
  PopoverArrow,
  PopoverCloseButton,
  PopoverBody,
  PopoverProps,
} from "@chakra-ui/react"
import {QuestionIcon} from "@chakra-ui/icons"

interface HelpButtonProps extends PopoverProps {}

const HelpButton = (props: HelpButtonProps) => {
  const startWord = useStore((state) => state.startWord)

  return (
    <Popover {...props}>
      <PopoverTrigger>
        <IconButton icon={<QuestionIcon />} aria-label="Help" />
      </PopoverTrigger>
      <Portal>
        <PopoverContent mx="4">
          <PopoverArrow />
          <PopoverCloseButton />
          <PopoverBody>
            Playing is easy! Enter words that rhyme with <u>{startWord}</u> above.
          </PopoverBody>
        </PopoverContent>
      </Portal>
    </Popover>
  )
}

export type {HelpButtonProps}
export {HelpButton}
