import useStore from "@/store"

import {IconButton, IconButtonProps} from "@chakra-ui/react"
import {RepeatIcon} from "@chakra-ui/icons"

interface RestartButtonProps extends Omit<IconButtonProps, "aria-label"> {}

const RestartButton = (props: RestartButtonProps) => {
  const initialize = useStore((state) => state.initialize)

  return (
    <IconButton
      icon={<RepeatIcon />}
      aria-label="Restart"
      onClick={() => initialize(true)}
      {...props}
    />
  )
}

export type {RestartButtonProps}
export {RestartButton}
