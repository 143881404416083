import {SettingsModal} from "@/components"

import {IconButton, IconButtonProps, useDisclosure} from "@chakra-ui/react"
import {SettingsIcon} from "@chakra-ui/icons"

interface SettingsButtonProps extends Omit<IconButtonProps, "aria-label"> {}

const SettingsButton = (props: SettingsButtonProps) => {
  const {isOpen, onOpen, onClose} = useDisclosure()

  return (
    <>
      <IconButton
        aria-label="Search database"
        icon={<SettingsIcon />}
        onClick={onOpen}
        {...props}
      />
      <SettingsModal isOpen={isOpen} onClose={onClose} />
    </>
  )
}

export type {SettingsButtonProps}
export {SettingsButton}
