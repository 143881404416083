import {HeadingBox, HeadingBoxProps} from "@/components"
import {Box} from "@chakra-ui/react"

interface BannerProps extends HeadingBoxProps {}

const Banner = (props: HeadingBoxProps) => {
  return (
    <Box position="absolute" left="0" top="0" p="2">
      <HeadingBox {...props}>🔤 Rima</HeadingBox>
    </Box>
  )
}

export type {BannerProps}
export {Banner}
